import '../../App.css';
import './skills.css'

function SkillCard(props) {
    return (
        <div className='flex skillCard'>
            <img src={props.skillImgLink}
                alt="profile" width="240" height="auto" className='skillImg' />
            {props.skillName}
        </div>
    );
}

export default SkillCard;