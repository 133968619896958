import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import "../../App.css"
import "./contact.css"


const ContactForm = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_t498jlj', 'template_r3ydo69', form.current, '_mm6eJpQyPj6LIpnD')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <div className="item-container">
            <h1>Contact Me</h1>
            <div className='contactDiv'>
                <form ref={form} onSubmit={sendEmail}>
                    <div>
                        <div className='firstRow'>
                            <div className='firstRow each'>
                                <input type="text" name="user_name" placeholder='Name' />
                            </div>
                            <div className='firstRow each'>
                                <input type="email" name="user_email" placeholder='Email' />

                            </div>
                        </div>
                        <div className='messageDiv'>
                            <textarea name="message" placeholder='Send Me a Message' />
                        </div>
                        <input type="submit" value="Send" />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ContactForm;