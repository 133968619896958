import "./App.css";
import About from "./components/aboutSection/aboutMe.js";
import Skills from "./components/Skills/skills";
import ProjectSection from "./components/project/ProjectsSection";
import ContactForm from "./components/contactForm/contact";
import ContactInfo from "./components/contactInfo/conatctInfo";

import Triangle from "./components/decorativeComponents/triangle";

import { useRef, useEffect, useState } from "react";

function App() {
  return (
    <div className="App">
      <div className={"App-section title-section"}>
        <h1 id="Name">Fady Philips</h1>
        <h2>Software Developer</h2>
      </div>
      <div className="App-section">
        <div className="item-container">
          <About />
        </div>
      </div>
      <div className="App-section">
        <h1>Skills</h1>
        <div>
          <Skills />
        </div>
      </div>
      <div className="App-section">
        <h1>Projects</h1>
      </div>
      <ProjectSection />
      <div className="App-section">
        <ContactForm />
      </div>
      <ContactInfo />
      <div className="App-section credits">
        Icons by{" "}
        <a target="_blank" href="https://icons8.com">
          Icons8
        </a>
      </div>
    </div>
  );
}

export default App;
