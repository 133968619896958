import React, { useState } from "react";
import "./contactInfo.css";
import githubIcon from "../../assets/icons/icons8-github.svg";
import emailIcon from "../../assets/icons/emailIcon.png";
import linkedInIcon from "../../assets/icons/icons8-linkedin-128.png";

import Icon from "../linkIcon/icon";

const ContactInfo = () => {
  return (
    <div className="contact-info">
      <Icon
        iconPng={githubIcon}
        iconLink={
          "https://github.com/FadyAPhilips?tab=overview&from=2023-11-01&to=2023-11-17"
        }
      />
      <Icon
        iconPng={linkedInIcon}
        iconLink={"https://www.linkedin.com/in/fady-philips-a48b4319b/"}
      />
      <Icon iconPng={emailIcon} iconLink={"mailto:fadyaphilips97@gmail.com"} />
    </div>
  );
};

export default ContactInfo;
