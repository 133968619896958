import '../../App.css';
import './icon.css'

function Icon(props) {

    return (
        <div >
            <a href={props.iconLink} target="_blank">
                <img
                    src={props.iconPng}
                    alt="logo"
                    className='icon'
                />
            </a>
        </div >
    );
}

export default Icon;