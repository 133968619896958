import '../../App.css';
import Project from './Project';
import githubIcon from '../../assets/icons/icons8-github.svg'
import linkIcon from '../../assets/icons/icons8-link-64.png'
import gymTheoryScreen from '../../assets/screenshots/gymTheoryScreenGroup.png'
import myflexScreen from '../../assets/screenshots/myflexMock.png'
import calcScreen from '../../assets/screenshots/CalcScreenshot.png'

function Projects() {

    const projectsList = [
        {
            title: "GymTheory",
            imgSource: gymTheoryScreen,
            text: ["Gym Theory is a React Native mobile app developed using Expo, harnessing the power of the AWS serverless stack, including Lambdas, API Gateway, and DynamoDB. The application facilitates the logging of daily workouts, allowing users to meticulously record sets with weight and rep details.",
                "Redux ensures efficient state management, enhancing the app's responsiveness. Additionally, Gym Theory integrates an external API for comprehensive workout information and features a functional search option for streamlined navigation. The project's architecture showcases proficiency in React Native development and the creation of a robust serverless backend using AWS services. "],
            tags: ["React Native", "Redux", "javascript", "Expo", "AWS - Lambda", "AWS - DynamoDB", "AWS - API Gaitway"],
            links: [
                {
                    icon: githubIcon,
                    link: "https://github.com/FadyAPhilips/GymTheory"
                }
            ]
        },
        {
            title: "MyFlex",
            imgSource: myflexScreen,
            text: ["MyFlex is a collaborative endeavor, a React.js movie watchlist app crafted within a proficient team of four developers. This MERN stack project empowers users to manage their movie preferences, tracking films they intend to watch and those already enjoyed. Emulating a professional work environment, we maintained code quality through efficient task distribution and an agile management system. Rigorous code reviews ensured a streamlined development process.",
                "Beyond development, I played a pivotal role in refining the app's user experience by contributing to its design. Utilizing UI/UX tools like Figma, I actively participated in creating an intuitive and visually appealing web application. "
            ],
            tags: ["Team Project", "React.js", "MERN", "javascript", "CSS", "Figma"],
            links: [
                {
                    icon: githubIcon,
                    link: "https://github.com/MyFelx/App"
                },
                {
                    icon: linkIcon,
                    link: "http://ec2-3-141-165-79.us-east-2.compute.amazonaws.com:3333/"
                }
            ]
        },
        {
            title: "Simple Calculator",
            imgSource: calcScreen,
            text: ["As a team leader in a Frontend course by Kreativestorm, I spearheaded the creation of a simple calculator app using vanilla HTML, CSS, and Javascript. The project engaged a team of four web development novices, and within a week, we successfully delivered a functional calculator. Emphasizing the importance of semantic HTML, the project adhered to best practices and was deployed using GitHub Pages.",
                "In addition to project management, I took on a mentoring role, guiding team members who were new to web development. This experience not only demonstrated my leadership capabilities in coordinating a successful project but also showcased my commitment to fostering learning and skill development within a collaborative setting."],
            tags: ["Team Project", "Semantic HTML", "CSS", "Javascript"],
            links: [
                {
                    icon: githubIcon,
                    link: "https://github.com/FadyAPhilips/Calculator-FE"
                },
                {
                    icon: linkIcon,
                    link: "https://fadyaphilips.github.io/Calculator-FE/"
                }
            ]
        }
    ]

    const projects = projectsList.map((project) =>
        <Project
            title={project.title}
            imgSource={project.imgSource}
            text={project.text}
            tags={project.tags}
            links={project.links}
        />
    )

    return (
        <div className='App-section'>
            {projects}
        </div>

    );
}

export default Projects;