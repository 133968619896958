import React from "react";
import "../../App.css";
import "./about.css";

function About() {
  return (
    <div>
      <h2>About Me</h2>
      <div className="flex about-container">
        <div className="about-section">
          <p className="text">
            Welcome to my portfolio! I'm a computer science graduate known for
            creativity, vision, and logical thinking. I'm passionate about tech,
            collaborative projects, and continuous learning. My studies involved
            various projects that honed my collaboration and communication
            skills, emphasizing the value of teamwork in finding innovative
            solutions. In my free time, I delve into PC hardware, keeping up
            with the latest advancements, and enjoy strategic board games.
            Thanks for learning about me—I'm eager to connect with fellow tech
            enthusiasts and collaborators!
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
