import '../../App.css';
import './project.css'
import Icon from '../linkIcon/icon'

function Project(props) {

    const taglist = props.tags.map((tag) =>
        <div className='tag'>
            {tag}
        </div>
    )

    const iconList = props.links.map((link) =>
        <Icon
            iconPng={link.icon}
            iconLink={link.link}
        />
    )

    const textList = props.text.map((para) =>
        <p className='description'>
            {para}
        </p>
    )

    let imgBorder
    if (props.imgBorder) {
        imgBorder = "2px white solid"
    }

    return (
        <div className="item-container">
            <h2>
                {props.title}
            </h2>
            <div className='projectContainer'>
                <div className='imgContainer'>
                    <img
                        src={props.imgSource}
                        alt="Carousel Image"
                        className='projectImg'
                        style={{ border: imgBorder }}
                    />
                </div>
                <div className='desContainer'>
                    {textList}
                    <div className='tagContainer'>
                        {taglist}
                    </div>
                    <div className='flex'>
                        {iconList}
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Project;