import '../../App.css';
import './skills.css'
import SkillCard from './skillCard';

function Skills() {


    return (
        <div className='flex skills-container'>
            <SkillCard skillName={"JavaScript"} skillImgLink={"https://upload.wikimedia.org/wikipedia/commons/d/d4/Javascript-shield.svg"} />
            <SkillCard skillName={"HTML5"} skillImgLink={"https://upload.wikimedia.org/wikipedia/commons/6/61/HTML5_logo_and_wordmark.svg"} />
            <SkillCard skillName={"CSS3"} skillImgLink={"https://upload.wikimedia.org/wikipedia/commons/d/d5/CSS3_logo_and_wordmark.svg"} />
            <SkillCard skillName={"Bash Scripting"} skillImgLink={"https://bashlogo.com/img/symbol/svg/full_colored_dark.svg"} />
            <SkillCard skillName={"AWS - Serverless stack"} skillImgLink={"https://cdn.worldvectorlogo.com/logos/aws-2.svg"} />
            <SkillCard skillName={"Node.js"} skillImgLink={"https://cdn.worldvectorlogo.com/logos/nodejs-icon.svg"} />
            <SkillCard skillName={"MongoDB"} skillImgLink={"https://cdn.worldvectorlogo.com/logos/mongodb-icon-1.svg"} />
            <SkillCard skillName={"Expo"} skillImgLink={"https://cdn.worldvectorlogo.com/logos/expo-1.svg"} />
            <SkillCard skillName={"Figma"} skillImgLink={"https://upload.wikimedia.org/wikipedia/commons/3/33/Figma-logo.svg"} />
            <SkillCard skillName={"React.js"} skillImgLink={"https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg"} />
            <SkillCard skillName={"React-Native"} skillImgLink={"https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg"} />
            <SkillCard skillName={"Redux"} skillImgLink={"https://cdn.worldvectorlogo.com/logos/redux.svg"} />
            <SkillCard skillName={"Git"} skillImgLink={"https://cdn.worldvectorlogo.com/logos/git-icon.svg"} />
            <SkillCard skillName={"Github Actions"} skillImgLink={"https://cdn.worldvectorlogo.com/logos/github-icon-1.svg"} />
            <SkillCard skillName={"Java"} skillImgLink={"https://cdn.worldvectorlogo.com/logos/java-4.svg"} />
            <SkillCard skillName={"Python"} skillImgLink={"https://cdn.worldvectorlogo.com/logos/python-5.svg"} />
            <SkillCard skillName={"C++"} skillImgLink={"https://cdn.worldvectorlogo.com/logos/c.svg"} />
            <SkillCard skillName={"OOP"} skillImgLink={"https://quizizz.com/media/resource/gs/quizizz-media/quizzes/7997c2e3-955b-4c7d-86e5-bb13f5749dce?w=900&h=900"} />
        </div>

    );
}

export default Skills;